$(document).ready(function(){
  	
	$('.banner-slider').not('.slick-initialized').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		infinite: true,
		arrows: true,
		draggable: false,
		delay: 3000,
    prevArrow: $('.banner-prev'),
    nextArrow: $('.banner-next'),
    pauseOnHover: false,
	});

	$('.service-slider').not('.slick-initialized').slick({
		autoplay: true,
		infinite: true,
		delay: 3000,
    prevArrow: $('.service-prev'),
    nextArrow: $('.service-next')
	});

	$('.testimonials-slider').not('.slick-initialized').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: false,
		infinite: true,
		arrows: false,
		dots: true,
		draggable: false,
		responsive: [
			{
				breakpoint: 920,
				settings: {
					arrows: false,
					autoplay: true,
				}
			},
		]
	});

	$('.gallery-slider').not('.slick-initialized').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		delay: 3000,
		infinite: true,
		arrows: true,
		dots: false,
		draggable: false,
		adaptiveHeight: true
	});

	$('.service-gallery__slider').not('.slick-initialized').slick({
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		delay: 3000,
		infinite: false,
		arrows: false,
		dots: true,
		draggable: false,
		adaptiveHeight: true
	});
  	
	$('.clients-slider').not('.slick-initialized').slick({
		slidesToShow: 8,
		slidesToScroll: 1,
		autoplay: true,
		speed: 2500,
		cssEase: 'linear',
		autoplaySpeed: 1,
		centerMode: true,
		easing: 'linear',
		infinite: true,
		arrows: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		draggable: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 520,
				settings: {
					slidesToShow: 2,
				}
			},
		]
	});

	$('.sector-slider').not('.slick-initialized').slick({
		slidesToShow: 6,
		slidesToScroll: 1,
		autoplay: true,
		speed: 2500,
		cssEase: 'linear',
		autoplaySpeed: 1,
		centerMode: true,
		easing: 'linear',
		infinite: true,
		arrows: false,
		pauseOnHover: false,
		pauseOnFocus: false,
		draggable: false,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 5,
				}
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 4,
				}
			},
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 3,
				}
			},
			{
				breakpoint: 520,
				settings: {
					slidesToShow: 2,
				}
			},
		]
	});
});
